<template>
<banner componentName="下载中心"></banner>
  <div class="main-download">
    <div class="main-download-instructions">
      <div class="main-download-instructions-title">产品说明书下载</div>
      <a href="/download/WiFi智能计量重合闸使用说明书.docx" download="WiFi智能计量重合闸使用说明书.docx">1.wifi智能重合闸使用说明书</a>
      <a href="/download/涂鸦智能插座说明书.pdf" download="涂鸦智能插座说明书.pdf"><br />2.涂鸦智能插座说明书</a>
      <a href="/download/WIFI款漏电保护器说明书.pdf" download="WIFI款漏电保护器说明书.pdf"><br />3.WIFI款漏电保护器说明书</a>
      <a href="/download/4G重合闸说明书.pdf" download="4G重合闸说明书.pdf"><br />4.4G重合闸说明书</a>
      <a href="/download/WIFI通断说明书-涂鸦.pdf" download="WIFI通断说明书-涂鸦.pdf"><br />5.WIFI通断说明书-涂鸦</a>
      <a href="/download/WIFI通断说明书-易微联.pdf" download="WIFI通断说明书-易微联.pdf"><br />6.WIFI通断说明书-易微联</a>
      <a href="/download/4G重合闸说明书(4P).pdf" download="4G重合闸说明书(4P).pdf"><br />7.4G重合闸说明书(4P)</a>
    </div>

  </div>
  <Footer></Footer>
</template>

<script>
import banner from './banner'
import Footer from './Footer'
export default {
  name: "download",
  components:{
    banner,
    Footer
  }
}
</script>

<style scoped>
.main-download{
  height: 60vh;
  padding:7rem 2rem;
}
.main-download-instructions-title{
  font-size:20px;
  padding:20px 0;

}
a{
  height:2rem;
  text-decoration:none;
  color:black;
}
</style>