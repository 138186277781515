<template>
<div class="main">
  <banner componentName="智慧用电解决方案"></banner>
  <div class="smartCase-content ">
    <div class="block text-center pc-platform" m="t-4">
      <el-carousel trigger="click" height="500px">
        <el-carousel-item v-for="item in bannerImg" :key="item">
          <div class="banner-main">
            <div class="text-content">
              <h1 class="title">{{item.title}}</h1>
              <div class="desc">{{item.disc}}</div>
              <div class="link-title">
                <a :href="item.link" class="">{{item.linkTitle}}</a>
             </div>
            </div>
            <img :src=item.imgPath alt="" class="img-banner">
          </div>

        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="block text-center mobile" m="t-4">
      <el-carousel trigger="click" height="580px">
        <el-carousel-item v-for="item in bannerImg" :key="item">
          <div class="banner-main">
            <div class="text-content">
              <h1 class="title">{{item.title}}</h1>
              <div class="desc">{{item.disc}}</div>
              <div class="link-title">
                <a :href="item.link" class="">{{item.linkTitle}}</a>
              </div>
            </div>
            <img :src=item.imgPath alt="" class="img-banner">
          </div>

        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="platformDesc">
      <div class="platform-desc-title">三端一平台，完整的业务链</div>
      <div class="platform-desc-main">
        <div class="platform-desc-content-amin">
          <div class="platform-desc-content" v-for="(item,index) in  platForm" :key="item">
            <el-collapse v-model="activeName" accordion >
              <el-collapse-item :title="item.title" :name='index+1' >
                <div class="platform-desc">{{item.disc}}</div>
                <a class="platform-linkTile" :href="item.link">{{item.linkTitle}}</a>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="desc-img">
          <img :src="'/img/smartEleCase/platform-'+((activeName)||1)+'.png'" alt="">
        </div>
      </div>


    </div>
    <div class="whyUs">
      <div class="left-content">
       <div> {{whyUsInfo.title}}</div>
      </div>
      <div class="right-content">
        <div class="content-item" v-for="item in whyUsInfo.info" :key="item">
         <div class="content-item-box">
           <img :src="item.icon" alt="">
           <div class="content-title">{{item.title}}</div>
           <div class="content-content">{{item.content}}</div>
         </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import banner from './banner'
import Footer from './Footer'
import { ref } from 'vue'
export default {
  name: "smartEleCase",
  components:{
    banner,
    Footer
  },
  setup(){
    let activeName = ref(1)
    const swiperArray = [1,2,3]
    let index = 0
   const  switchImgIndex=  function(){
     activeName.value = swiperArray[index]
      index = (index+1)%(swiperArray.length)
   }
   const startTimer =  function () {
      setInterval(switchImgIndex, 4000);
    }
     startTimer()
    return {
      activeName
    }
  },
  data(){
   return {
     bannerImg:[
       {
         imgPath:'/img/smartEleCase/banner2.jpeg',
         title:'智慧用电解决方案',
         disc:'完整的商用智慧用电物联网控制系统，多协议兼容，完美满足绿色建筑的设备管理及能源管理要求',
         linkTitle:'联系我们',
         link:'/contactUs'
       },
       {
         imgPath:'/img/smartEleCase/banner1.jpeg',
         title:'硬件体验套装',
         disc:'一站式硬件解决方案，感受传统用电向智能化转型，打造专属智慧生活',
         linkTitle:'前往购买',
         link:'https://puandajj.tmall.com'
       },
       {
         imgPath:'/img/smartEleCase/banner3.png',
         title:'多种通讯协议解决方案',
         disc:'WIFI，蓝牙，ZigBee，4G等多种通讯协议，完美满足多种场景需求',
         linkTitle:'联系我们',
         link:''
       }
     ],
     platForm:[
       {
         imgPath:'/img/smartEleCase/platform-1.png',
         title:'智慧行业APP',
         disc:'支持设备批量添加与控制，场景管理，故障报警等，灵活便捷的管理项目',
         linkTitle:'了解更多',
         link:'/contactUs'
       },
       {
         imgPath:'/img/smartEleCase/platform-2.png',
         title:'Web管理平台',
         disc:'支持可视化管理，数据大盘，权限分配，点位图配置，多地项目统一后台管理',
         linkTitle:'了解更多',
         link:'/contactUs'
       },
       {
         imgPath:'/img/smartEleCase/platform-3.png',
         title:'开放OpenAPI',
         disc:'企业可对接自己的平台，小程序，APP；无需重新搭建前后端平台',
         linkTitle:'了解更多',
         link:'/contactUs'
       },
     ],
     whyUsInfo:{
       title:'为什么选择我们？',
       info:[
         {
           title:'低成本的智慧用电解决方案',
           icon:'/img/smartEleCase/lowPrice.jpeg',
           content:'标准化免开发方案，即开即用。\n' +
               '大幅降低研发投入，快速实现产品智能化。\n' +
               '低至传统照明控制方案的30%',
         },
         {
           title:'数字化运营管理',
           icon:'/img/smartEleCase/yunying.jpeg',
           content:"打破地域限制，实现多项目的远程数字化及可视化管理。\n" +
               "实时监测设备运行状态和能耗状态，提升运维效率。",
         },
         {
           title:'丰富的物联能力',
           icon:'/img/smartEleCase/lot.jpeg',
           content:"Zigbee / Bluetooth Mesh/ Wi-Fi等多协议兼容。\n" +
               "模组标准化设计，不同协议间模组Pin to Pin兼容，产品无缝扩展。",
         },
         {
           title:'强大的生态链',
           icon:'/img/smartEleCase/ecosystem.jpeg',
           content:"基于“Powered by Tuya”丰富的产品生态，涂鸦商照解决方案可实现多品类多品牌设备兼容。\n" +
               "节省额外对接成本，互联互通，减少重复，可持续拓展。",
         },
         {
           title:'金融级的网络数据安全',
           icon:'/img/smartEleCase/jingrong.jpeg',
           content:"引领全球 IoT 领域的安全合规标准。\n" +
               "通过ISO/CQC/EU GDPR/NCC/CCPA/SRRC等认证测试。\n" +
               "全球部署，守护网络数据安全。",
         }
       ]
     }

   }
  }
}
</script>

<style scoped>
.mobile{
  display:none;
}
.smartCase-content{
  padding-top:61px;
}

.el-carousel__item img{
  display: block;
  height: 500px;
  width: 80%;
  margin-left:50%;

}
.banner-main{
  display:flex;
}
.text-content{
  display: block;
  position: absolute;
  width: 50%;
}
.title{
  margin:100px 0 20px 80px;
  font-size:42px;
  font-weight:800;
}
.desc{
  margin:0 0 44px 80px;
  color: #606266;
  font-weight:300;
}
.link-title{
  margin:0 0 44px 80px;
  color: #606266;
  font-weight:700;
  background-color:rgb(253, 190, 2);
  width: 100px;
  height:48px;
  padding:0 28px;
  line-height:45px;
  text-align:center;
  border-radius:48px;
}
a{
  text-decoration: none;
  color:#fff;
}
.platformDesc{
  position: relative;
  background-color: rgb(250, 250, 250);
}
.platform-desc-content-amin{
  width:40%;
  display: flex;
  flex-direction: column;
  margin-left:70px;
}

/*.platform-img{*/
/*  width:500px;*/
/*}*/
.desc-img{
  margin-left: 90px;
}
.desc-img img{
  width:700px;
  height: 500px;
  transition: all .5s;
}
.platform-desc-title{
  text-align: center;
  padding:90px;
  font-weight:900;
  font-size:42px;
}
.platform-desc-main{
  display: flex;
  padding-bottom: 90px;
}
.platform-desc{
  font-size:16px;
  color: #333333;
  font-weight:200;
}
/deep/.el-collapse-item__header{
  background-color:transparent;
  height: 70px;
  font-size:18px;
  padding:10px 0;

}
/deep/.el-collapse{
  --el-collapse-content-bg-color:transparent;
}
.platform-linkTile{
  padding:10px 0;
  color:#FDBE02;
  font-weight:700;
}
/*为什么选择我们*/
.whyUs{
  display: flex;
  padding-bottom: 90px;
}
.left-content{
  width:50%;
  padding-top:90px;
}
.left-content div{
  font-size:40px;
  font-weight:800;
  margin-left:70px;
}
.content-item{
  width:250px;
  margin: 70px 70px 20px 70px;
}
.right-content{
  display:flex;
  width:80%;
  flex-direction:row;
  flex-wrap: wrap;

}
.content-item-box{
  width:280px;
  margin-right:70px
}
.content-item-box img{
  width:48px;
  height:48px;
}
.content-title{
  font-size:18px;
  font-weight:600;
  padding:20px 0px;
}
.content-content{
  color: #606266;
  font-weight: 300;
}
@media screen and (max-width:500px){
  .mobile{
    display:block;
  }
  .pc-platform{
    display:none;
  }
  .text-content{
    width:100%;
    transition: all 500ms ease;
    position: relative;
  }
  .title{
    margin:0;
    padding:5rem 1rem 1rem 1rem;
    text-align:center;
    font-size:2rem;

    font-weight:800;
    overflow: auto;
    word-wrap: break-word;
  }
  .desc{
    width:350px;
    margin:auto;
    text-align:center;
    color: black;
    line-height: 1.5rem;
    font-weight:300;

  }
  .link-title{
    margin:40px auto;
    color: #606266;
    font-weight:700;
    background-color:rgb(253, 190, 2);
    width: 100px;
    height:48px;
    padding:0 28px;
    line-height:45px;
    text-align:center;
    border-radius:48px;
  }
  .el-carousel__item img{
    display: block;
    height: 250px;
    width: 100%;
    margin-left:0;

  }
  .banner-main{
    flex-direction:column;
  }
  .platform-desc-title{
    text-align: center;
    padding:90px 20px 20px 20px;
    font-weight:900;
    font-size:32px;
  }
  .platform-desc-main{
    display: block;
    padding-bottom: 90px;
  }
  .platform-desc{
    font-size:16px;
    color: #333333;
    font-weight:200;
  }
  .platform-desc-content-amin{
    width:90%;
    display: flex;
    flex-direction: column;
    margin-left:auto;
    padding: 0 20px;
  }
  /deep/.el-collapse-item__header{
    height: 60px;
    font-weight:900;
  }
  .desc-img{
    margin:auto;
    padding:10px;
  }
  .desc-img img{
    width:100%;
    height: 220px;
    transition: all .5s;
  }

  /*为什么选择我们*/
  .whyUs{
    display: block;
    padding-bottom: 90px;
  }
  .left-content{
    width:100%;
    padding-top:90px;
    text-align:center;
    font-size:32px;
  }
  .left-content div{
    font-size:32px;
    font-weight:800;
    margin:0;
  }
  .content-item{
    width:100%;
    margin: 0;
    padding:20px;
  }
  .right-content{
    margin-top: 70px;
    display:flex;
    width:100%;
    flex-direction:row;
    flex-wrap: wrap;

  }
  .content-item-box{
    width:100%;
    margin:0;
  }
  .content-item-box img{
    width:48px;
    height:48px;
  }
  .content-title{
    font-size:18px;
    font-weight:600;
    padding:20px 0px;
  }
  .content-content{
    color: #606266;
    font-weight: 300;
  }
  /deep/.el-collapse-item__header{
    padding:10px;

  }
  /deep/.el-collapse{
    --el-collapse-content-bg-color:transparent;
  }

}


</style>