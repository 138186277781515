<template>
<banner componentName="产品中心"></banner>
  <div class="productCenter-main">
    <div class="swiper-main">
     <div class="swiper-banner">
       <swiper class="swiper-banner-swiper" :autoplay="swiper_options.autoplay"  :loop="swiper_options.loop" :speed="swiper_options.speed"
               :pagination="{ clickable: true }" :scrollbar="{ draggable: true }">
         <swiper-slide >
           <div class="img-cus"><img class="swiper-img" src="/img/productCenterBanner/banner.png" alt="">
             <div class="img-cus-banner2-text">
               <div class="img-cus-banner2-text-1">智能开关解决方案</div>
               <div class="img-cus-banner2-text-2"> 智能联网，随时随地让电器焕然一新</div>
               <div class="img-cus-banner2-text-3">
                 <router-link to="/productCenter"><div>点击查看</div></router-link>
               </div>
             </div>
           </div>
         </swiper-slide>
         <swiper-slide ><div class="img-cus"><img class="swiper-img" src="/img/productCenterBanner/banner4.jpeg" alt="">
           <div class="img-cus-banner2-text">
             <div class="img-cus-banner2-text-1">智能重合闸解决方案</div>
             <div class="img-cus-banner2-text-2"> 多款产品，满足你的一切用电需求</div>
             <div class="img-cus-banner2-text-3">
               <router-link to="/productCenter"><div>点击查看</div></router-link>
             </div>
           </div>
         </div></swiper-slide>
<!--         <swiper-slide ><img class="swiper-img" src="/img/productCenterBanner/banner2.jpeg" alt=""></swiper-slide>-->
<!--         <swiper-slide ><img class="swiper-img" src="/img/productCenterBanner/banner3.jpeg" alt=""></swiper-slide>-->
<!--         <swiper-slide ><img class="swiper-img" src="/img/productCenterBanner/banner4.jpeg" alt=""></swiper-slide>-->
<!--         <swiper-slide ><img class="swiper-img" src="/img/productCenterBanner/banner5.jpeg" alt=""></swiper-slide>-->
       </swiper>
     </div>

    </div>
    <div class="new-product-main">
      <div class="new-product">
        <div class="new-product-title">最新发布</div>
        <div class="new-product-product">
          <div class="new-product-productDetail">
            <img src="/img/newproduct/product-1-new.png" alt="">
            <router-link :to="'/products?id='+1"><div class="newproduct-title">自动重合闸</div>
            </router-link>
            <div class="newproduct-description">自动断电 | 一键开关 | 适合大电流 | 多重安全保障</div>
          </div>

            <div class="new-product-productDetail">
              <img src="/img/newproduct/product-2-new.png" alt="">
              <router-link :to="'/products?id='+2"><div class="newproduct-title">智能重合闸（4G)</div>
              </router-link>
              <div class="newproduct-description">详细电流显示 | 随时数据上传 | 多模式安全控制 | 免费流量</div>
            </div>
          <div class="new-product-productDetail">
            <img src="/img/newproduct/product-3-new.png" alt="">
            <router-link :to="'/products?id='+3"><div class="newproduct-title">WIFI智能开关</div>
            </router-link>
            <div class="newproduct-description">WIFI连接 | 一键自动断电 | 多模式控制 | 随时控制用电</div>
          </div>
          <div class="new-product-productDetail">
            <img src="/img/newproduct/product-4-new.png" alt="">
            <router-link :to="'/products?id='+4"><div class="newproduct-title">4P重合闸</div>
            </router-link>
            <div class="newproduct-description">大电流控制 | 一键开关 | 防触电设置 | 4P输入</div>
          </div>
        </div>
      </div>
    </div>
    <div class="category-main">
      <div class="category-content">
        <div class="category-main-title">
          <div>种类专区</div>
        </div>
<!--        重合闸专区-->
        <div class="ele-category">
          <div class="ele-category-title">
            <img src="/img/category/elet.jpeg" alt="">
            <p class="ele-category-title-text">重合闸专区</p>
            <p class="ele-category-title-text-2">让普通用电快速智能化</p>
          </div>
          <div class="ele-product-main">
            <router-link :to="'/products?id='+1">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/product-1-whitebg.png" alt="">
                </div>
                <div class="ele-product-titleWrap">自动重合闸</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close_wifi.png" alt="">
                </div>
                <div class="ele-product-titleWrap">WIFI重合闸</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close_4g.png" alt="">
                </div>
                <div class="ele-product-titleWrap">4G重合闸</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>


          </div>
        </div>
<!--        开关专区-->
        <div class="ele-category">
          <div class="ele-category-title">
            <img src="/img/category/close_cover.jpeg" alt="">
            <p class="ele-category-title-text">开关专区</p>
            <p class="ele-category-title-text-2">让断电随心所欲</p>
          </div>
          <div class="ele-product-main">
            <router-link :to="'/products?id='+3">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/product-3-whitebg.png" alt="">
                </div>
                <div class="ele-product-titleWrap">WIFI开关</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>


          </div>
        </div>
      </div>
<!--      手机版-->
      <div class="category-content-mobile">
        <div class="category-main-title-mobile">
          <div class="category-main-title-mobile">种类专区</div>
        </div>
<!--        重合闸专区手机版-->
        <div class="ele-category-mobile">
          <div class="ele-category-title-mobile">
            <img src="/img/category/elet-mobile.jpeg" alt="">
            <p class="ele-category-title-text-mobile">重合闸专区</p>
            <p class="ele-category-title-text-2-mobile">让普通用电快速智能化</p>
          </div>
          <div class="ele-product-main-mobile">
            <router-link :to="'/products?id='+1">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/product-1-whitebg.png" alt="">
                </div>
                <div class="ele-product-titleWrap">自动重合闸</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close_wifi.png" alt="">
                </div>
                <div class="ele-product-titleWrap">WIFI重合闸</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close_4g.png" alt="">
                </div>
                <div class="ele-product-titleWrap">4G重合闸</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>

          </div>
      </div>
<!--        开关专区手机版-->
        <div class="ele-category-mobile">
          <div class="ele-category-title-mobile">
            <img src="/img/category/close_cover_mobile.jpeg" alt="">
            <p class="ele-category-title-text-mobile">开关专区</p>
            <p class="ele-category-title-text-2-mobile">让断电随心所欲</p>
          </div>
          <div class="ele-product-main-mobile">
            <router-link :to="'/products?id='+3">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/product-3-whitebg.png" alt="">
                </div>
                <div class="ele-product-titleWrap">WIFI开关</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">自动重合闸</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>
            <router-link to="/">
              <div class="ele-product">
                <div class="ele-product-imgWrap">
                  <img src="/img/category/close.jpeg" alt="">
                </div>
                <div class="ele-product-titleWrap">双模调光断路器</div>
              </div>
            </router-link>

          </div>
        </div>
    </div>
    </div>

  </div>
  <Footer></Footer>
</template>

<script>
import banner from './banner'
import Footer from './Footer'
import SwiperCore, {Autoplay,Pagination,Navigation} from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css'
import {reactive} from "vue";
SwiperCore.use([Autoplay,Pagination,Navigation]);
export default {
  name: "productCenter",
  components: {
    Swiper,
    SwiperSlide,
    banner,
    Footer
  },
  setup() {
    // swiper相关配置属性放在swiper_options这个变量里
    let swiper_options = reactive({
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      loop: true,
      speed: 10,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    })
    // 将swiper_options返回给模板中的swiper组件使用
    return {swiper_options};
  }
}
</script>

<style scoped>
.productCenter-main{
  width:100%;
  margin: 0 auto;
}

.swiper-main{
  height:300px;
  width:85%;
  padding:70px 0 20px 0 ;
  margin: 0 auto;
}
.swiper-banner{
  padding:20px 0;
  width:100%;
  border-radius: 7px;
}
.swiper-banner-swiper{
  width:100%;
  margin: 0 auto;
}
.swiper-img{
  height:350px;
  width:100%;
  border-radius: 7px;
}
.swiper-slide{
  margin:0 auto;
  width:85%;
}
/deep/.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
}
/deep/.swiper-pagination-bullet {
  background-color:hsla(0,0%,100%,.4);
  width: 30px;
  height: 3px;
  border-radius: 0;
  opacity: 1;}
/deep/.swiper-pagination-bullet-active{
  background: #fff;
}
.new-product{
  width:85%;
  margin:80px auto;

}
.new-product-title {
  font-size: 24px;
  line-height: 33px;
  padding-bottom: 20px;
}
.new-product-product{
  display:flex;
  width:1220px;
  flex-wrap:wrap;
  justify-content: space-between;
}
.new-product-productDetail{
  width:24%;
}

.new-product-productDetail>img{
  height:200px;
  border-radius: 7px;
  width:100%;
}
.newproduct-title{
  font-size:16px;
  font-weight:400;
  line-height:30px;
  height:30px;
  text-align: left;
}
.newproduct-title:hover{
  color:#fe5500;
}
.newproduct-description{
  color:rgba(0,0,0,.45);
  line-height:30px;
  font-size: 14px;
  height:30px;
  text-align: left;

}
.category-main{
  background-color:#f5f5f5;
}
.category-content{
  width:85%;
  margin:0 auto;
}
.category-main-title{
  font-size: 24px;
  line-height: 33px;
  padding: 20px 0;
}
.ele-category{
  display:flex;
  padding-bottom: 20px;
  width:1220px;
}
.ele-category-title{
  position:relative;
  width:240px;
}
.ele-category-title>img{
  width:240px;
  border-radius:10px;
}
.ele-category-title-text{
  position:absolute;
  top:1%;
  left:8%;
  color:#fff;
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: 500;
}
.ele-category-title-text-2{
  position:absolute;
  top:13%;
  left:8%;
  color:#fff;
  font-size: 16px;
  margin-bottom: 12px;
  opacity:.7;
}
.ele-product-main{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
}
.ele-product{
  height:189px;
  width:228px;
  margin:0 0 16px 16px;
  background-color:#fff;
  border-radius:10px;
  padding-top: 10px;
}
.ele-product:hover{
  opacity:.7;
}
.ele-product-imgWrap{
  margin:0 auto;
}
.ele-product-imgWrap>img{
  width:100px;
  height:100px;
  margin:10% 29%;
}
a{
  text-decoration:none;

}
.ele-product-titleWrap{
  font-size: 14px;
  font-weight: 400;
  color: #131415;
  margin-top: -10px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: center;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.category-content-mobile{
  display:none;
}
a{
  color:#131415;
}
.img-cus-banner2-text{
  position:absolute;
  top:25%;
  left:10%;
  z-index:10000000;
}
.img-cus-banner2-text-1{
  font-size:40px;
  color:#fff;
  line-height:35px;
  text-align: left;
  font-weight: 700;
  letter-spacing:7px;
}
.img-cus-banner2-text-2{
  padding:20px 0 0 0;
  font-size:20px;
  color:#fff;
  line-height:30px;
  text-align: left;
  font-weight: 400;
  letter-spacing:3px;
}
.img-cus-banner2-text-3{
  width:350px;
  display:flex;
  flex-wrap:wrap;
  padding:20px 0;
  font-size:18px;
  color:#fff;
  line-height:30px;
  text-align: left;
  font-weight: 400;
  letter-spacing:3px;
}
.img-cus-banner2-text-3>a{
  padding:20px 20px 20px 0;
  background-color:#ff4800;
  color:#fff;
  width:90px;
  height:10px;
  border: none;
  border-radius: 30px;
  line-height:10px;
  text-align:center;
  display:none;
}
.img-cus-banner2-text-3:hover{
  opacity:.8;
}
.img-cus-banner2-text-3>a>div{
  line-height:10px;
  text-align:center;
  width:100%;
  margin-left:10%;
}

@media screen and (max-width:500px){
  .new-product-main{
    background-color:#f5f5f5
  }
  .swiper-main{
    height:200px;
    width:95%;
    padding:50px 0 0px 0 ;
    margin: 0 auto;
  }
  .swiper-img{
    height:200px;
  }
  .swiper-banner{
    padding:20px 0;
    width:100%;
    border-radius: 10px;
  }
  .new-product{
    width:95%;
    margin:0 auto;
    padding:40px 0;


  }
  .new-product-product{
    display:flex;
    width:100%;
    flex-wrap:wrap;
    justify-content: space-between;
  }
  .new-product-productDetail{
    width:49%;
    height:260px;
    border-radius:10px;
    margin:5px 0;
    background-color:white;
  }
  .new-product-productDetail>img{
    height:130px;
  }
  .newproduct-title {
    padding:0 5px 20px 5px;
  }
  .newproduct-description {
    padding:0 5px 20px 5px;
  }
  .new-product-title {
    font-size: 18px;
    line-height: 33px;
    padding-top: 20px;
    padding-bottom: 0;
  }
  /deep/.main{
    background-color:white;
  }
  /deep/.list-mobile{
    color:rgba(0,0,0,.65)
  }
  .category-content{
    display:none;
  }
  .category-content-mobile{
    display:block;
    width:95%;
    margin: 0 auto;
    border-radius:10px;

  }
  .category-main{
    background-color:#f5f5f5;
  }
  .category-main-title-mobile{
    font-size: 18px;
    line-height: 33px;
  }
  .ele-category-mobile{
    border-radius: 10px;
    padding-bottom: 20px;
  }
  .ele-category-title-mobile{
    position:relative;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    background-color:#fff;
  }
  .ele-category-title-mobile>img{
    width:100%;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    margin: 0 auto;

  }
  .ele-category-title-text-mobile{
    position: absolute;
    top:1%;
    left:7%;
    font-size:20px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 4px;
    z-index: 1;
  }
  .ele-category-title-text-2-mobile{
    position: absolute;
    top:27%;
    left:7%;
    font-size: 14px;
    margin-bottom: 12px;
    opacity:1;
    font-weight:200;
    color:#fff;
  }
  .ele-product-main-mobile{
    background-color:#fff;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    display:flex;
    flex-wrap: wrap;
    margin:0 auto;
    justify-content:space-around;

  }
  .ele-product-main-mobile>a{
    width:30%;
  }
  .ele-product{
    width:100%;
    height:136px;
  }
  .ele-product{
    margin:0;

  }
  .ele-product-imgWrap>img{
    margin:0 auto;
  }
  .ele-product-titleWrap{
    color:#131415;
    margin:5px 0;
  }
  a{
    color:#131415;
  }
  .img-cus-banner2-text{
    position:absolute;
    top:25%;
    left:10%;

    z-index:10000000;
  }
  .img-cus-banner2-text-1{
    font-size:20px;
    color:#fff;
    line-height:20px;
    text-align: left;
    font-weight: 500;
    letter-spacing:4px;
  }
  .img-cus-banner2-text-2{
    padding:20px 0 0 0;
    font-size:14px;
    color:#fff;
    line-height:20px;
    text-align: left;
    font-weight: 400;
    letter-spacing:2px;
  }
  .img-cus-banner2-text-3{
    width:350px;
    display:flex;
    flex-wrap:wrap;
    padding:20px 0;
    font-size:12px;
    color:#fff;
    line-height:30px;
    text-align: left;
    font-weight: 400;
    letter-spacing:3px;
  }
  .img-cus-banner2-text-3>a{
    padding:10px 10px 10px 0;
    background-color:#ff4800;
    color:#fff;
    width:70px;
    height:10px;
    border: none;
    border-radius: 30px;
    line-height:10px;
    text-align:center;
    font-size: 13px;
    display:none;
  }
  .img-cus-banner2-text-3:hover{
    opacity:.8;
  }
  .img-cus-banner2-text-3>a>div{
    line-height:10px;
    text-align:center;
    width:100%;
    margin-left:10%;
  }


}
</style>