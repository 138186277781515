<template>
<banner componentName="关于我们"></banner>
  <div class="main-ABOUT">
    <div class="title">关于我们</div>
    <div class="slogn">以客户需求和前沿发展理念为驱动力</div>
    <div class="detail">
      <img src="/img/AboutUs.png" alt="">
    </div>
    <div class="detail">
      <div>      乐清市菲舍尔电子科技有限公司成立于2018年01月02日，注册地位于中国电器之都浙江省温州市乐清市柳市镇。经营范围包括电子元器件、电子线路板组件、配电开关控制设备、交流接触器、直流接触器、高低压开关、互感器、软启动变频器、继电器、电气配套零部件研发、制造、加工、销售。<br></div>
      <div>      以客户需求和前沿发展理念为驱动力，我们不断提升管理模式与管理水平，不断提升核心竞争能力，使公司始终处于行业地位。未来，我们还将为推进中国电子技术的发展和建设而不懈努力。</div>
      <div>      历经近几年的发展，公司已成为集研发、销售、生产和服务于一体的现代高科技企业，其产品销往全球各地，构筑了面向全国的经营和服务网络，涵盖智能家居、信息化、信息安全、云计算与大数据、智慧化应用、网络可视化和特种电器等业务领域，涉及政府、公安、检察院、司法、文教、交通、能源、金融、电信和企业等行业应用。</div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import banner from './banner'
import Footer from './Footer'
export default {
  name: "AboutUs",
  components:{
    banner,
    Footer
  }
}
</script>

<style scoped>
.content{
  display:none;
}
.main-ABOUT{
  padding:170px 0 0 0 ;

}
.title{
  width:200px;
  height:50px;
  margin: 0 auto;
  font-size:30px;
  letter-spacing: 5px;
  text-align: center;

}
.slogn{
  width:400px;
  height:100px;
  margin:0 auto;
  font-size:18px;
  padding:10px 0 10px 0px;
  color:#3e64ff;
  font:18px "Microsoft YaHei","微软雅黑","arial","tahoma","MicrosoftJhengHei";
  text-align: center;
}
.detail{
  font:14px "Microsoft YaHei","微软雅黑","arial","tahoma","MicrosoftJhengHei";
  color:#555555;
  padding:10px 150px;
  line-height: 2;
  letter-spacing: .5px;
}
.detail>div{
  padding:15px 0;
}
.detail>img{
  width:100%;
  height:300px;
}
@media screen and (max-width:500px){
  .title{
    width:200px;
    height:50px;
    margin: 0 auto;
    font-size:1.5rem;
    letter-spacing: 5px;
    text-align: center;

  }
  .slogn{
    width:25rem;
    height:3rem;
    margin:0 auto;
    font-size:18px;
    padding:10px 0 10px 0px;
    color:#3e64ff;
    font:18px "Microsoft YaHei","微软雅黑","arial","tahoma","MicrosoftJhengHei";
    text-align: center;
  }
  .detail{
    font:14px "Microsoft YaHei","微软雅黑","arial","tahoma","MicrosoftJhengHei";
    color:#555555;
    padding:10px 2.5rem;
    line-height: 2;
    letter-spacing: .5px;
  }
  .detail>div{
    padding:15px 0;
  }
  .detail>img{
    width:100%;
    height:10rem;
  }
}
</style>