<template>
  <banner></banner>
<div class="main-product">
  <ul>
    <li v-for="item in productInfo.details" :key="item" >
      <img class="li-img" :src="item" alt="">
    </li>
  </ul>
</div>
  <Footer></Footer>
</template>

<script>
import {useRoute} from 'vue-router'
import axios from "axios";
import {ref} from 'vue';
import banner from './banner'
import Footer from './Footer'
export default {
  name: "products",
  components:{
    banner,
    Footer
  },
  setup(){
    const route = useRoute()
    const productId = route.query.id;
    const productInfo = ref({})
    const handleQueryProductInfo = (id)=>{
      axios.get("/api/productInfo.json"
      ).then(
          (response) => {
            const data = response.data;
            productInfo.value = data[id]

          })
    }
    handleQueryProductInfo(productId)
    return {
      productInfo
    }
  }
}
</script>

<style scoped>
.main-product{
  margin:0 20%;
  padding:60px 0 0 0;
}
.li-img{
  width:100%;
  padding:0;
  margin:0 auto;
  display: block;
}
@media screen and (max-width:500px){
  .main-product{
    margin:0;
  }
  ul{
    margin:0;
    padding:0;
  }
  .li-img{
    width:100%;
    padding:0;
    margin:0 auto;
  }
}
</style>